<style lang="less" scoped>
#notice-detail {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tab-top-line {
    width: 100%;
    height: 2px;
    background-color: #F7F8FA;
    margin-top: 12px;
  }
  .notice-info {
    margin: 30px 40px 60px 40px;
    ::v-deep .ant-form-item {
      margin-bottom: 15px ;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px ;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 10px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      height: 46px;
      width: 80%;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-children, input {
      height: 46px;
      line-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }
    .flex-vw {
      display: flex;
      ::v-deep .ant-form-item-control-wrapper,
      ::v-deep .ant-form-item-control,
      ::v-deep .ant-form-item-children,
      ::v-deep .ant-cascader-picker,
      ::v-deep .ant-cascader-input {
        width: 200px;
      } 
      .cus-left {
        margin-left: 80px;
      }
    }
    .tips-div {
      margin-left: 30px;
      margin-top: 30px;
      display: flex;
      .tips-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 15px;
      }
      .tips-list {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        display: inline-block;
        margin-left: 20px;
        width: 65%;
      }
    }
  }
  .save-button {
    width: 480px;
    height: 60px;
    margin-top: 50px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="notice-detail" id="notice-detail">
    <div class="tab-top">
      <table-title />
    </div>
    <div class="tab-top-line"></div>
    <div class="notice-info">
      <template>
        <a-form-model ref="infoForm" :model="form">
          <a-form-model-item label="公告标题" prop="notice_title" ref="notice_title">
            <a-input v-model="form.notice_title" placeholder="请输入公告标题"/>
          </a-form-model-item>
        </a-form-model>
      </template>
      <div class="tips-div">
        <div class="tips-title">公告内容</div>
        <div class="tips-list">
          <a-input type="textarea" v-model="form.notice_content" placeholder="请输入公告内容" style="height: 200px"/>
        </div>
      </div>
      <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
    </div>
  </div>
</template>

<script>
import { createNotice, fetchNoticeInfo, updateNotice } from "@/api/notice"
export default {
  name: "NoticeDetail",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
  },
  data() {
    return {
      loading: false,
      noticeId: undefined,
      form: {
        notice_title: undefined,
        notice_content: undefined,
      },
    };
  },
  created() {
    this.noticeId = this.$route.query.noticeId;
    this.initData();
  },
  methods: {
    initData() {
      if (this.noticeId != undefined) {
        this.getNoticeDetail()
      }
    },
    /// 获取用户信息
    async getNoticeDetail() {
      try {
        let res = await fetchNoticeInfo({
          notice_id: this.noticeId
        });
        this.form.notice_title = res.notice_title;
        this.form.notice_content = res.notice_content;
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit() {
      if (!this.form.notice_title) {
        this.$message.error("请输入公告标题");
        return
      }
      if (!this.form.notice_content) {
        this.$message.error("请输入公告内容");
        return
      }
      this.createNoticeData();
    },
    async createNoticeData() {
      this.loading = true;
      try {
        let params = {
          notice_title: this.form.notice_title,
          notice_content: this.form.notice_content,
        };
        if (this.noticeId == undefined) {
          params["notice_status"] = 1;
          await createNotice(params);
          this.$message.success("公告添加成功");
        }else {
          params["notice_id"] = this.noticeId;
          await updateNotice(params);
          this.$message.success("公告更新成功");
        }
        this.loading = false;
        const that = this;
        setTimeout(() => {
          if (that.noticeId == undefined) {
            that.$router.push({ name: "notice"});
          }else {
            that.$router.go(-1);
          }
        }, 500);
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
  }
};
</script>