import https from '../utils/https'

// 公告列表接口
export function fetchNoticelist(params) {
  return https.fetchPost('/api/backstage/notice/list', params)
}

// 获取公告信息接口
export function fetchNoticeInfo(params) {
  return https.fetchGet('/api/backstage/notice/info', params)
}

// 创建公告接口
export function createNotice(params) {
  return https.fetchPost('/api/backstage/notice/create', params)
}

// 更新公告属性接口
export function updateNotice(params) {
  return https.fetchPost('/api/backstage/notice/update', params)
}

// 更新公告转改接口
export function updateNoticeStatus(params) {
  return https.fetchPost('/api/backstage/notice/modifystatus', params)
}